@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,600');
html {
    scroll-behavior: smooth;
    height: 100%;
    width: 100%;
}

@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: smooth;
        height: 100%;
        width: 100%;
    }
}

h2 {
    color: white;
}

body {
    margin: 0;
    background: #222222;
    font-family: 'ProximaNova-Bold',Arial,sans-serif;
    font-weight: 800;
    width: 100%;
    height: 100%;
    display: table;
}

.container-left {
    display: inline-block;
    background: #222222;
    opacity: 1;
    float: left;
    z-index: 99999;
    width: 50%;
    height: 60px;
}

.container-right {
    display: inline-block;
    background: green;
    opacity: 1;
    z-index: 99999;
    float: left;
    overflow: hidden;
    width: 50%;
    height: 60px;
}

header {
    height: 60px;
    width: 100%;
    top: 0;
    position: fixed;
    opacity: 1;
    transition: top 0.2s ease-in-out;
    z-index: 1;
    background: #222222;
}

.header-off {
    border-bottom-color: #F758A5;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    transition: top 0.2s ease-in-out;
}

.header-up {
    top: -63px;
}

header::after {
    content: '';
    display: table;
    clear: both;
}

footer {
    bottom: 0;
    margin-top:auto;
    overflow: hidden;
    /*position: fixed;*/
    box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
    height: 60px;
    display: block;
    width: 100%;
    background: #222;
    font-family: "Courier New";
}

.logo {
    float: left;
    height: 60px;
    width: 500px;
    font-size: 30px;
    margin-left: 30px;
    position: relative;
}

.logo-image {
    float: left;
    width: 120px;
    vertical-align: center;
    height: 32px;
    padding-top: 14px;
    padding-bottom: 14px;
}

.logo-image-mobile {
    float: left;
    width: 120px;
    vertical-align: center;
    height: 32px;
    padding-top: 14px;
    padding-bottom: 14px;
}

.logo-mobile {
    float: right;
    height: 50px;
    width: 60px;
    text-align: center;
    font-size: 30px;
    right: 0;
    top: 0;
    margin-top: 5px;
    margin-right: 10px;
    border: 3px solid #444444;
    color: #444444;
    position: relative;
    overflow: hidden;
}
